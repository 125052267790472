import React from 'react';
import './Loading.css'

const Loading = () => {
    return (
        <div className='loading'>
            <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <h2>Loading</h2>
        </div>
    );
};

export default Loading;