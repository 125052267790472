import React, { useEffect, useState } from 'react';
import Slider from '../../components/Slider/Slider';
import listBg from '../../assets/images/list-bg.jpg'
import logoIcon from '../../assets/icons/location-pin-svgrepo-com.svg'
import './List.css'
import { db } from '../../fire';
import { collection, getDocs } from 'firebase/firestore';
import Loading from '../../components/Loading/Loading';

const List = () => {
    const [cars, setCars]=useState([])
    const fetchData = async () => {
        try {
          const querySnapshot = await getDocs(collection(db, 'vehicles'));
          const fetchedData = [];
          querySnapshot.forEach((doc) => {
            fetchedData.push({ id: doc.id, ...doc.data() });
          });
          setCars(fetchedData);
        } catch (error) {}
    };

    useEffect(()=>{
        fetchData()
    },[])


    const bgStyles = {
        background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${listBg})`,
        backgroundSize: "cover", 
        backgroundPosition: "center", 
        backgroundRepeat: "no-repeat"
    }

    return (
        <div className='list'>
            <div className="bar">
                <a rel="noreferrer" target="_blank" href="https://maps.app.goo.gl/Y1NRkqHuFjVPa2pR9">
                    <p className='bar-address'>
                        <img loading='lazy' src={logoIcon} width={30} alt="location" />
                        555 W Street Rd Unit C Warminster PA 18974
                    </p>
                    <hr />
                </a>
            </div>
            <div className="list-offers" style={bgStyles}>
                <h2 className='list-offers__head'>Vehicle Offers</h2>
                <div className="list-offers__listing">
                    <div className="listing-cars">
                        {
                            cars && cars.length ? <Slider list={cars}/> : <Loading/>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default List;