import React from 'react';
import './Home.css'
import headerImg from '../../assets/images/head-image.jpg';
import successImg from '../../assets/icons/success-icon.png';
import handShakeImg from '../../assets/images/handshake.jpg';
import conditionsImg from '../../assets/images/conditions.jpeg';
import findCarImg from '../../assets/images/find-car.jpg';
import quoteImg from '../../assets/images/quote.jpg'
import { useNavigate } from 'react-router-dom';
import { redirection } from '../../utils/consts';

const Home = () => {
    const navigate = useNavigate()
    const darkenBackImages = (url)=>{
        return {
            background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5)10%, rgba(0, 0, 0, 0.7)15%, rgba(0, 0, 0, 0)100%), url(${url})`,
            backgroundSize: "cover", 
            backgroundPosition: "center", 
            backgroundRepeat: "no-repeat"
        }
    }

    const headerStyles = {
        background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${headerImg})`,
        backgroundSize: "cover", 
        backgroundPosition: "center", 
        backgroundRepeat: "no-repeat"
    }

    const dealBlockstyles ={
        ...headerStyles,
        background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${handShakeImg})`,
    }

    const advantages=[
        'Next day pick up',
        '100% Verified cars',
        'Conditions for everyone',
        'A wide range of cars',
    ]

    const offers=[
        {
            title:'Request a quote',
            image:quoteImg,
            id:'1231',
            content:'Request a quote',
            link:'/contacts'
        },
        {
            title:'Find your car',
            image:findCarImg,
            id:'3442',
            content:'View cars',
            link:'/list'
        },
        {
            title:'Conditions',
            image:conditionsImg,
            id:'2434',
            content:'View Conditions',
            link:'/about'
        }
    ]

    return (
        <div className='home'>
            <div style={headerStyles} className="home-header">
                <h2>Toyota Camry 2025</h2>
                <button onClick={()=>redirection(navigate, '/list')} className="home-header__btn">Find your car</button>
            </div>
            <div style={dealBlockstyles} className="home-deal-block">
                <div className="deal-advantages">
                    {
                        advantages.map(item=>(
                            <div key={item} className="advantage-item">
                                <img loading='lazy' width={70} src={successImg} alt="success"/>
                                <span className='advantage-text'>{item}</span>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="home-offers">
                {
                    offers.map(item=>(
                        <div key={item.id} style={darkenBackImages(item.image)} className="offer-item">
                            <p className='offer-item__text'>{item.title}</p>
                            <button onClick={()=>redirection(navigate, item.link)} className='offer-item__btn'>{item.content}</button>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default Home;