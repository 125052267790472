import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import mailIcon from '../../assets/icons/mail-alt-3-svgrepo-com.svg';
import locationIcon from '../../assets/icons/location-pin-svgrepo-com.svg'
import phoneIcon from '../../assets/icons/phone-flip-svgrepo-com.svg'
import './Contact.css'; 
import { gmailPublicKey, gmailServiceId, gmailTemplateId } from '../../utils/consts';

const Contact = () => {
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
          .sendForm(gmailServiceId, gmailTemplateId, form.current, {
            publicKey: gmailPublicKey,
          })
          .then(
            () => {},
            (error) => {},
        );
    };

  return (
    <div className="contact-page">
        <div className="contact-info">
            <h2>Contact Information</h2>
            <p><img loading='lazy' width={25} src={phoneIcon} alt="phone" />Phone: +17078070707</p>
            <hr />
            <p><img loading='lazy' width={25} src={mailIcon} alt="mail" />Email: jsarm.finance@gmail.com</p>
            <hr />
            <p><img loading='lazy' width={25} src={locationIcon} alt="location"/>Address: 555 W Street Rd Unit C Warminster PA 18974</p>
            <hr />
        </div>
        <div className="contact-form">
            <h2>Contact Us</h2>
            <form ref={form} onSubmit={sendEmail}>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input type="text" id="name" name="user_name" placeholder="Your Name" />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input type="email" id="email" name="user_email" placeholder="Your Email" />
                </div>
                <div className="form-group">
                    <label htmlFor="phone">Phone Number:</label>
                    <input type="text" id="phone" name="user_phone" placeholder="Your Phone Number" />
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message:</label>
                    <textarea id="message" name="message" placeholder="Your Message"></textarea>
                </div>
                <button type="submit">Send</button>
            </form>
      </div>
    </div>
  );
};

export default Contact;
