export const redirection=(navlinkFunc, link)=>{
    navlinkFunc(link)
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    })
}

export const gmailServiceId=process.env.REACT_APP_GMAIL_SERVICE_ID;
export const gmailTemplateId=process.env.REACT_APP_GMAIL_TEMPLATE_ID
export const gmailPublicKey=process.env.REACT_APP_GMAIL_PUBLIC_KEY