import React from 'react';
import './Footer.css'
import { Link, useNavigate } from 'react-router-dom';
import { redirection } from '../../utils/consts';


const Footer = () => {
  const navigate = useNavigate()
  return (
        <footer className="site-footer">
          <div className="footer-contact footer-container">
            <div className="contact-redirect">
                <button onClick={()=>redirection(navigate, '/contacts')} className='footer-contact__btn'>Contact Us</button>
            </div>
            <hr/>
          </div>
          <div className="footer-container">
            <div className="footer-container__columns row">
              <div className="col-sm-12 col-md-6 footer-column__item">
                <h6>About Us</h6>
                <p className="text-justify">Welcome to <i>JSARoyalMotors </i>, where luxury meets reliability. With a legacy of excellence spanning [X] years, our dealership is committed to providing unparalleled service and an extensive selection of premium vehicles. From sleek sedans to robust SUVs, each car on our lot embodies quality and sophistication. Our team of dedicated professionals strives to exceed your expectations, ensuring a seamless and enjoyable purchasing experience from start to finish. At JSA Royal Motors, we're not just selling cars; we're crafting unforgettable journeys. Visit us today and discover the epitome of automotive luxury.</p>
              </div>
              <div className="col-xs-6 col-md-3 footer-column__item">
                <h6>Vehicles</h6>
                <ul className="footer-links">
                  <li><Link to="/list">All Vehicles</Link></li>
                  <li><Link to="/list">SUVs</Link></li>
                  <li><Link to="/list">Crossovers</Link></li>
                  <li><Link to="/list">Hybrid</Link></li>
                </ul>
              </div>
    
              <div className="col-xs-6 col-md-3 footer-column__item">
                <h6>Quick Links</h6>
                <ul className="footer-links">
                  <li><Link to="/about">About Us</Link></li>
                  <li><Link to="/contacts">Contact Us</Link></li>
                  <li><Link to="/contacts">Contribute</Link></li>
                  <li><Link to="/about">Privacy Policy</Link></li>
                  <li><Link to="/about">Sitemap</Link></li>
                </ul>
              </div>
            </div>
            <hr/>
          </div>
          <div className="footer-container">
            <div className="row">
              <div className="col-md-8 col-sm-6 col-xs-12">
                <p className="copyright-text">Copyright &copy; 2024 All Rights Reserved by 
                <Link to="/"> Scanfcode</Link>.
                </p>
              </div>
            </div>
          </div>
    </footer>
    );
};

export default Footer;