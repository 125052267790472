import { NavLink, useNavigate } from "react-router-dom";
import { BurgerMenu } from "../BurgerMenu/BurgerMenu";
import { useState } from "react";
import './Navbar.css'
import { redirection } from "../../utils/consts";


export const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const navigate = useNavigate()

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const closeShowBar = ()=>{
    setShowNavbar(false)
  }

  return (
    <nav className="navbar">
      <div className="container">
        <div onClick={()=>redirection(navigate, '/')} className="logo">
            <h2>JSARoyalMotors</h2>
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <BurgerMenu />
        </div>
        <div className={`nav-elements  ${showNavbar && "active"}`}>
          <ul>
            <li>
              <NavLink onClick={closeShowBar} to="/">Home</NavLink>
            </li>
            <li>
              <NavLink onClick={closeShowBar} to="/about">About</NavLink>
            </li>
            <li>
              <NavLink onClick={closeShowBar} to="/list">Vehicles</NavLink>
            </li>
            <li>
              <NavLink onClick={closeShowBar} to="/contacts">Contact</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};