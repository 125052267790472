import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Navbar } from './components/Navbar/Navbar';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Contact from './pages/Contacts/Contact';
import List from './pages/List/List';
import Footer from './components/Footer/Footer';

const MainRoutes = () => {
    return (
        <BrowserRouter>
            <Navbar />
            <Routes>
                <Route exact path="/" element={<Home/>} />
                <Route path="/about" element={<About/>} />
                <Route path="/contacts" element={<Contact/>} />
                <Route path="/list" element={<List/>} />                
            </Routes>
            <Footer/>
        </BrowserRouter>
    )
};

export default MainRoutes;