import React from 'react';
import aboutHeader from '../../assets/images/about-header.jpg'
import location from '../../assets/icons/location-pin-svgrepo-com.svg'
import find from '../../assets/images/find-car.jpg'
import trust from '../../assets/images/trust-bg.png'
import './About.css'
import Counter from '../../components/Counter/Counter';

const About = () => {
    const headerStyles = {
        background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${aboutHeader})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover", 
        backgroundPosition: "center", 
    }

    const offerBlockstyles ={
        ...headerStyles,
        background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${find})`,
    }

    return (
        <section className="about">
            <div style={headerStyles} className="about-header">
                <div className="about-header__address">
                    <img loading='lazy' src={location} alt="location" />
                    <a rel="noreferrer" target="_blank" href="https://maps.app.goo.gl/Y1NRkqHuFjVPa2pR9">
                        <div>
                            <h2>555 W Street Rd Unit C Warminster PA 18974</h2>
                            <hr />
                        </div>
                    </a>
                </div>
            </div>
            <div className="about-offer" style={offerBlockstyles}>
            <div className="marquee">
                <div>
                    <h2>Any car you desire</h2>
                    <h2>Any car you desire</h2>
                </div>
            </div>
            <div className="marquee">
                <div>
                    <h2>Any car you desire</h2>
                    <h2>Any car you desire</h2>
                </div>
            </div>
            </div>
            <div className="about-trust">
                <img loading='lazy' className='about-trust__img' src={trust} alt="car" />
                <Counter/>
            </div>
        </section>
    );
};

export default About;