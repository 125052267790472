import React, { useEffect, useState } from 'react';
import './Counter.css'; // Import your CSS file for styling

const Counter = () => {
  const [count, setCount] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    if (!isPaused) {
      const timeout = setTimeout(() => {
        setCount((prevCount) => {
          if (prevCount < 100) {
            return prevCount + 1;
          } else {
            setIsPaused(true); // Pause the animation when count reaches 100
            setTimeout(() => {
              setCount(0); // Reset count to 0 after 3 seconds
              setIsPaused(false); // Resume the animation
            }, 3000);
            return 100; // Keep count at 100 during the pause
          }
        });
      }, 15); // Interval duration for smooth animation

      return () => clearTimeout(timeout); // Cleanup function to clear the timeout
    }
  }, [count, isPaused]); // Run effect whenever count or isPaused changes

  return <div className="counter">{count}% Trust</div>;
};

export default Counter;
