import React, { useState } from 'react';
import './Slider.css'; // Import your CSS file for styling
import { useNavigate } from 'react-router-dom';
import { redirection } from '../../utils/consts';

const Slider = ({ list }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate()

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === list.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? list.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="carousel">
        <div className="carousel-content">
        {list.map((item, index) => (
          <div
            key={index}
            className={
              index === currentIndex ? 'slide active' : 'slide inactive'
            }
          >
            <h3>{`${item.year} ${item.model}`}</h3>
            <p className="vinCode">VIN Code: {item.vin}</p>
            <div className="carousel-img">
              <img src={item.img} alt={item.model} />
            </div>
            <p className="price">${item.price}</p>
            <button onClick={()=>redirection(navigate, '/contacts')} className='carousel-contact-btn'>Contact</button>
          </div>
        ))}
      </div>
        <div className="carousel-action">
            <button onClick={prevSlide} className="prev">
                &#10094;
            </button>
            <span>{currentIndex+1} of {list.length}</span>
            <button onClick={nextSlide} className="next">
            &#10095;
            </button>
        </div>
    </div>
  );
};

export default Slider;
