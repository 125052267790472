import React from 'react';
import MainRoutes from './Routes';

const App = () => {
  return (
    <div className='App'>
      <MainRoutes/>
    </div>
  );
};

export default App;